<script setup lang="ts">
import { UITextSmMedium, UITextSmRegular } from '@gohighlevel/ghl-ui'
import { useDocumentStore } from '../../store/document'
import DocumentStateActions from './DocumentStateActions.vue'
import { formatDateWithTZ } from '@gohighlevel/ghl-proposals-common'
import { computed } from 'vue'
import { useMobileView } from '@/composable'

const store = useDocumentStore()
const { isMobile } = useMobileView()

const isCCrecipient = computed(() => store.isCcRecipient)
const showExpiryDate = computed(() => {
  if (store.isAlreadyCompleted || store.isDraftDocument) {
    return false
  }
  return (
    !store.isDocumentExpired &&
    store.documentExpiryEnabled &&
    isCCrecipient.value === false
  )
})
</script>

<template>
  <div
    class="bg-gray-900 h-16 w-full fixedElement flex flex-col justify-center"
  >
    <div class="px-3 py-2 flex justify-between items-center gap-4 page:gap-0">
      <div class="w-4/12 flex flex-col items-start page:w-6/12">
        <div class="w-full" :title="store?.document.name">
          <UITextSmMedium class="text-white truncate">
            {{ store?.document.name }}
          </UITextSmMedium>
        </div>
        <div>
          <UITextSmRegular class="text-white capitalize">
            {{ $t('documentViewer.by') }}
            {{ store.document.updatedBy?.name }}</UITextSmRegular
          >
        </div>
      </div>
      <div class="w-6/12 flex justify-end page:w-6/12">
        <div
          class="flex items-center text-white mr-2"
          v-if="showExpiryDate"
          :class="{
            '[@media(max-width:520px)]:flex-col [@media(max-width:520px)]:items-end [@media(max-width:520px)]:text-sm':
              isMobile,
          }"
        >
          {{ $t('documentViewer.documentExpiresOn') }}
          {{
            store.document.expiresOn
              ? formatDateWithTZ(store.document.expiresOn, 'D MMMM YYYY')
              : ''
          }}
        </div>
        <DocumentStateActions />
      </div>
    </div>
  </div>
</template>

<style>
.fixedElement {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
</style>
