<script setup lang="ts">
import { UITextMdMedium, UITextMdNormal } from '@gohighlevel/ghl-ui'
import { PencilLineIcon } from '@gohighlevel/ghl-icons/24/outline'
import { useDocumentStore } from '@/store/document'
import { PropType, computed, inject, onMounted, ref, toRef } from 'vue'
import {
  IElement,
  IRecipient,
  PageElementOptions,
  SignatureElementOptions,
  getSignatureElementColorStyles,
  useTypography,
} from '@gohighlevel/ghl-proposals-common'
import SignatureModal from '../modals/SignatureModal.vue'

import {
  useDocument,
  useFillableFields,
  useSignature,
  usePrintAdjust,
} from '@/composable'
import { watch } from 'vue'
const props = defineProps({
  page: {
    type: Object as PropType<IElement<PageElementOptions>>,
    required: true,
  },
  element: {
    type: Object as PropType<IElement<SignatureElementOptions>>,
    required: true,
  },
  pageId: {
    type: String,
    required: true,
  },
  pageRef: {
    required: false,
    type: Object as () => HTMLElement,
  },
  parentRef: {
    required: false,
    type: Object as () => HTMLElement,
  },
  shift: {
    type: String,
    required: false,
    default: '0px',
  },
})

const localSignatureText = ref('')
const localParentRef = ref<HTMLElement | undefined>(props.parentRef)
const localPageRef = ref<HTMLElement | undefined>(props.pageRef)
watch(
  () => props.parentRef,
  newVal => {
    localParentRef.value = newVal
  },
  { immediate: true }
)
watch(
  () => props.pageRef,
  newVal => {
    localPageRef.value = newVal
  },
  { immediate: true }
)
const { position: printPosition, elRef } = usePrintAdjust(
  props.page,
  props.element,
  localPageRef,
  localParentRef
)
const showSignatureModal = ref(false)
const isPDFView = inject<boolean>('isPDFView')
const { isPreview } = useDocument()
const store = useDocumentStore()
const isCCrecipient = computed(() => store.isCcRecipient)
const isEditing = computed(() => store.isEditing)
const activeRecipient = computed(() => store.activeRecipient)
const { setActiveField, activeElement } = useFillableFields()
const { toCapitalize } = useTypography()
const { signatureText } = useSignature()

const recipient = computed(() => {
  const recipients = store.document?.recipients || []
  return (
    recipients.find(
      ({ id, entityName }) =>
        id === props.element?.component.options.recipient &&
        entityName === props.element?.component.options.entityName
    ) || ({} as IRecipient)
  )
})

const recipientName = computed(() => {
  const { firstName, lastName } = recipient.value as IRecipient
  return toCapitalize(firstName ? `${firstName} ${lastName || ''}` : '')
})

watch(
  [recipientName, signatureText],
  ([updateName, updatedText]) => {
    localSignatureText.value = updatedText || updateName
  },
  { immediate: true }
)
const isActiveSignatureElemForRecipient = computed(
  () =>
    store.activeRecipient?.id === props.element?.component.options.recipient &&
    store.activeRecipient?.entityName ===
      props.element?.component.options.entityName &&
    store.hasPrevRecipientsCompleted
)

const isRecipientHasCompleted = computed(() => {
  return (
    activeRecipient.value &&
    activeRecipient.value?.hasCompleted &&
    isActiveSignatureElemForRecipient.value
  )
})

const documentExpiryEnabled = computed(() => {
  if (store.documentExpiryEnabled) return true

  return false
})
const isDocumentExpired = computed(() => {
  if (store.isDocumentExpired) return true

  return false
})

const shouldEnableElement = computed(
  () =>
    !(
      isActiveSignatureElemForRecipient.value &&
      !isElementSigned.value &&
      store.hasPrevRecipientsCompleted &&
      isCCrecipient.value === false &&
      (!documentExpiryEnabled.value || !isDocumentExpired.value)
    )
)

const isAlreadyAccepted = computed(() => store.isAlreadyAccepted)
const isElementSigned = computed(
  () =>
    isPDFView ||
    props.element?.component?.options?.isSigned ||
    recipient.value.hasCompleted
)

const date = toRef(recipient.value.signedDate)
const customClassesForImg = computed(() => {
  const styles = props.element?.responsiveStyles.large
  const classArr = [styles?.align]
  return classArr
})
const dimensions = computed(
  () =>
    props.element?.responsiveStyles.large.dimensions || {
      height: 68,
      width: 190,
    }
)
const ghostStyles = computed(() => {
  if (props.element?.component.options?.isGhost) {
    const styleMap = props.element?.responsiveStyles.large as any
    const position = styleMap.position
    const scale = styleMap.scale

    const stylesArray = []
    if (position) {
      let styleStr = position?.preferBottom
        ? `bottom: ${position.bottom}px;`
        : `top: ${position.top}px; `
      styleStr += position?.preferRight
        ? `right: ${position.right}px;`
        : `left: ${position.left}px;`
      styleStr += 'z-index: 100;'

      stylesArray.push(styleStr)
    }
    if (scale) {
      stylesArray.push(
        `transform: scale(${scale.scaleX}, ${scale.scaleY}) translateZ(0);`
      )
    }
    if (dimensions.value) {
      stylesArray.push(
        `height: ${dimensions.value.height}px; width: ${dimensions.value.width}px;`
      )
    }
    stylesArray.push('position: absolute; display: flex;')
    return stylesArray.join(' ').trim()
  }
  return ''
})

const customStyleForImageParent = computed(() => {
  const styles = props.element?.responsiveStyles.large
  return {
    marginTop: styles?.marginTop,
    marginBottom: styles?.marginBottom,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginRight,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
    ...(styles?.transform ? { transform: styles.transform } : {}),
  }
})

onMounted(() => {
  if (store.isAlreadyAccepted) {
    store.updateSignatureElement(props.element?.id, props.pageId, true)
  }
})
const onUpdateSignature = (dataImg: string) => {
  const timestamp = new Date()
  date.value = timestamp.toISOString()
  store.updateSignatureForRecipient(recipient.value.id, dataImg, date.value)
  store.updateSignatureElement(props.element?.id, props.pageId, true, dataImg)
  const elms = store.getFilledSignatureElementForRecipient(recipient.value.id)
  elms.forEach(({ pageId, element: el }) => {
    store.updateSignatureElement(el.id, pageId, true, dataImg)
  })
}
const shouldShowSignatureModal = computed(
  () =>
    !isPreview &&
    !isAlreadyAccepted.value &&
    (!recipient.value.imgUrl || isElementSigned.value) &&
    recipient.value.id === activeRecipient?.value?.id &&
    recipient.value.entityName === activeRecipient?.value?.entityName &&
    !isRecipientHasCompleted.value &&
    !isCCrecipient.value
)
const onClick = async () => {
  if (
    !isEditing.value &&
    isActiveSignatureElemForRecipient.value &&
    !activeRecipient.value?.hasCompleted &&
    isCCrecipient.value === false
  ) {
    store.setIsEditing(true)
  }
  if (shouldShowSignatureModal.value) {
    showSignatureModal.value = true
    setActiveField(props.element?.id as string)
  } else {
    if (isActiveSignatureElemForRecipient.value) {
      const dataImg = activeRecipient.value?.imgUrl || ''
      store.updateSignatureElement(
        props.element?.id,
        props.pageId,
        true,
        dataImg as string
      )
    }
  }
}

const colorStyles = computed(() => {
  const dimensionsObj = dimensions.value
  const commonStyles = {
    ...(dimensionsObj
      ? {
          height: `${dimensionsObj.height}px`,
          width: `${dimensionsObj.width}px`,
        }
      : {}),
  }
  if (isElementSigned.value) {
    return { ...commonStyles } as {
      color: string
      backgroundColor: string
      borderColor: string
    }
  }
  return { ...commonStyles, ...getSignatureElementColorStyles(recipient.value) }
})
</script>

<template>
  <div
    ref="elRef"
    class="signature-el"
    :class="{ filled: isElementSigned }"
    :style="ghostStyles"
    :id="props.element?.id"
  >
    <span class="option--required" v-if="!isPDFView">*</span>
    <SignatureModal
      :id="props.element?.id"
      :signerName="localSignatureText"
      v-model:show="showSignatureModal"
      @update:signatureText="onUpdateSignature"
      :width="dimensions.width"
      :height="dimensions.height"
    />
    <div
      class="print:break-inside-avoid !break-inside-avoid box-border"
      :class="[customClassesForImg]"
    >
      <div
        :style="customStyleForImageParent"
        :class="{
          'cursor-pointer':
            isActiveSignatureElemForRecipient &&
            !isAlreadyAccepted &&
            isCCrecipient === false,
          'text-disabled cursor-not-allowed':
            (!isPDFView &&
              !isActiveSignatureElemForRecipient &&
              !isElementSigned) ||
            isCCrecipient === true,
        }"
      >
        <div
          :class="{ 'recipient-name': props.element?.version === 2 }"
          v-if="props.element?.component.options.showName"
        >
          <UITextMdMedium>{{ recipientName }}</UITextMdMedium>
        </div>

        <div
          @click="onClick"
          class="flex justify-center text-[14px] items-center whitespace-pre-line"
          :class="`text-[${colorStyles.color}]  
                  ${
                    !isElementSigned
                      ? `border rounded-sm ${
                          isPDFView || shouldEnableElement
                            ? 'border-disabled  bg-disabled pointer-events-none'
                            : `border-[${colorStyles.borderColor}]  bg-[${colorStyles.backgroundColor}]`
                        }`
                      : 'rounded-sm'
                  }
          `"
          :style="{
            ...colorStyles,
            pointerEvents: activeRecipient?.hasCompleted ? 'none' : 'auto',
            'border-width':
              activeElement && activeElement.id === props.element?.id
                ? '0px'
                : '1px',
          }"
        >
          <div
            v-if="recipient.imgUrl && isElementSigned"
            class="flex h-full max-w-full"
            style="flex: 1 0 auto"
          >
            <img
              crossorigin="anonymous"
              style="height: 100%; margin: auto 0; max-width: 100%"
              :src="props.element?.component?.options?.src || recipient.imgUrl"
              altText="signature"
            />
          </div>
          <div
            v-else
            class="flex items-center overflow-hidden"
            :class="`${
              shouldEnableElement
                ? 'text-disabled'
                : `text-[${colorStyles.color}]`
            } `"
          >
            <PencilLineIcon
              class="h-5 w-5 mr-2"
              :class="{
                'stroke-disabled': shouldEnableElement,
              }"
            />
            <UITextMdNormal
              :style="{
                'text-overflow': 'ellipsis',
                'white-space': 'nowrap',
                overflow: 'hidden',
              }"
              >{{ props.element?.component.options.text }}</UITextMdNormal
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.signature-el {
  position: relative;
  .recipient-name {
    position: absolute;
    top: -25px;
    left: 0;
  }
}
@media print {
  .signature-el {
    break-inside: avoid-page !important;
    // top: v-bind('topPosition') !important;
    transform: v-bind('printPosition.top') !important;
  }
}
</style>
