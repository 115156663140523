<script setup lang="ts">
import { useTypography } from '@/composable'
import { useDocumentStore } from '@/store/document'
import {
  UIDisplaySmSemibold,
  UITextSmMedium,
  UITextXsMedium,
  UITextXsRegular,
} from '@gohighlevel/ghl-ui'
import { computed } from 'vue'
import { formatDate } from '@/util'
import { Certificate } from '@gohighlevel/ghl-proposals-common'

const store = useDocumentStore()
const props = defineProps({
  participants: Object as any,
})

const participantsSortedBySignedTime = computed(() =>
  [...props.participants].sort((user1, user2) => {
    const date1 = new Date(user1.signed.time)
    const date2 = new Date(user2.signed.time)
    return date2.getTime() - date1.getTime()
  })
)
const refNumber = computed(() => store.certificate?.referenceNumber || '')
const sentOn = computed(
  () => store.certificate?.sent || ({} as Certificate['sent'])
)
const lastSignedContact = computed(
  () => participantsSortedBySignedTime.value[0]
)
// const pdfDownloadUrl = computed(() => store.certificate?.pdfVersionDownloadURL)
const { toCapitalize } = useTypography()
</script>

<template>
  <div class="px-7 mt-8">
    <UIDisplaySmSemibold class="text-gray-900">
      {{ $t('documentViewer.signatureCertificate') }}
    </UIDisplaySmSemibold>
    <div class="flex items-center justify-between mt-2 text-gray-700">
      <UITextXsRegular>
        {{ $t('documentViewer.referenceNumber') }}:
        <span class="uppercase">{{ refNumber }}</span>
      </UITextXsRegular>
      <UITextXsRegular class="flex items-center">
        {{ $t('documentViewer.sentOn') }}
        <UITextXsMedium class="ml-1">
          {{ formatDate(sentOn?.time) }} UTC
        </UITextXsMedium>
      </UITextXsRegular>
    </div>
    <div class="table-container p-5 !rounded-none mt-7">
      <table class="table border-collapse w-full">
        <thead>
          <tr>
            <th
              align="left"
              class="!bg-white !border-b-2 !border-b-gray-400 !px-0"
            >
              <UITextXsMedium style="font-weight: 600">
                {{ $t('documentViewer.signedBy') }}
              </UITextXsMedium>
            </th>
            <th
              align="left"
              class="!bg-white !border-b-2 !border-b-gray-400 !px-0"
            >
              <UITextXsMedium style="font-weight: 600">
                {{ $t('documentViewer.signature') }}
              </UITextXsMedium>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in participants"
            v-bind:key="`participant-${index}`"
          >
            <td class="!py-5">
              <div>
                <UITextSmMedium>
                  {{ toCapitalize(user.contact.name) }}
                </UITextSmMedium>
                <UITextXsRegular>{{ user.contact.email }}</UITextXsRegular>
                <UITextXsRegular class="mt-2">
                  {{ $t('documentViewer.viewed') }}:
                  {{ formatDate(user.viewed.time) }} UTC
                </UITextXsRegular>
                <UITextXsRegular>
                  {{ $t('documentViewer.signed') }}:
                  {{ formatDate(user.signed.time) }} UTC
                </UITextXsRegular>
              </div>
            </td>
            <td class="!py-5">
              <div class="img-container" v-if="user?.signature">
                <img
                  class="img"
                  :alt="user.contact.email"
                  :src="user.signature"
                  aria-hidden="true"
                />
              </div>
              <UITextXsRegular class="mt-2">
                {{ $t('documentViewer.ipAddress') }}:
                {{ user?.signed?.ip || '' }}
              </UITextXsRegular>
              <UITextXsRegular v-if="user?.signed?.location">
                {{ $t('documentViewer.location') }}:
                {{ user?.signed?.location }}
              </UITextXsRegular>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-3">
      <UITextXsRegular class="text-gray-700">
        {{ $t('documentViewer.documentCompletedByAllPartiesOn') }}
      </UITextXsRegular>
      <UITextXsRegular class="text-gray-700">
        {{ formatDate(lastSignedContact?.signed?.time) }} UTC
      </UITextXsRegular>
    </div>
    <!-- <div v-if="pdfDownloadUrl" class="flex justify-end mt-5">
      <QrCode :value="pdfDownloadUrl" />
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.img-container {
  width: 215px;
  height: 65px;
  border-radius: 4px;
  border: 1px solid var(--gray-300, #d0d5dd);
  text-align: center;
  .img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
    object-position: center;
  }
}
</style>
