<script setup lang="ts">
import { useDocumentStore } from '../../store/document'
import { useMobileView } from '../../composable'
import { computed } from 'vue'
import FillableFieldIndicator from './FillableFieldIndicator.vue'

const store = useDocumentStore()
const { isMobile } = useMobileView()

const isEditing = computed(() => store.isEditing)
const isEditable = computed(
  () =>
    store.isEditable &&
    !store.activeRecipient?.hasCompleted &&
    !(store.documentExpiryEnabled && store.isDocumentExpired)
)
</script>

<template>
  <div
    v-if="isEditable && isEditing"
    class="sign-btn-group absolute page:!fixed page:bottom-0 page:bg-white page:!top-[unset] page:!left-[unset] page:w-[100%] page:!z-[100] page:p-4 page:box-border"
    style="
      top: calc(100vh - 22em);
      left: calc(50vw - 38em);
      z-index: 1;
      box-shadow: 0px -2px 10px 4px #ddd;
    "
  >
    <div class="flex flex-col gap-4 page:flex-row page:justify-end">
      <FillableFieldIndicator :is-mobile-view="isMobile" />
    </div>
  </div>
</template>
