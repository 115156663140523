import {
  CurrencyConfigs,
  DynamicEditableField,
  DynamicEditableFieldProps,
  IntervalType,
  InvoiceType,
  LineItem,
  getCurrencyUtil,
} from '@gohighlevel/ghl-proposals-common'
import { DefineComponent, h } from 'vue'
import {
  UITextSmMedium,
  UITextSmRegular,
  UITextXsRegular,
} from '@gohighlevel/ghl-ui'

import ShowEditTableRow from './ShowEditTableRow.vue'
import { useDocumentStore } from '@/store/document'
import { useI18n } from 'vue-i18n'

const translationMap = {
  [IntervalType.MONTHLY]: 'common.repeatsMonthly',
  [IntervalType.WEEKLY]: 'common.repeatsWeekly',
  [IntervalType.DAILY]: 'common.repeatsDaily',
  [IntervalType.YEARLY]: 'common.repeatsYearly',
  [IntervalType.MINUTELY]: 'common.repeatsMinutely',
  [IntervalType.HOURLY]: 'common.repeatsHourly',
}

export const getProductListColumns = (
  currencyCode: string,
  elementVersion = 1,
  onUpdate: <T>(key: keyof T, value: any, row?: any) => void,
  currencySymbol = ''
) => {
  const store = useDocumentStore()
  const { t } = useI18n()
  const getPriceHelperText = (lineItem: LineItem) => {
    if (
      lineItem.priceType === InvoiceType.RECURRING &&
      lineItem.isSetupFeeItem
    ) {
      return t('common.onetime')
    } else if (
      lineItem.priceType === InvoiceType.RECURRING &&
      store.invoiceType === InvoiceType.RECURRING
    ) {
      return translationMap[store.intervalType]
        ? t(translationMap[store.intervalType])
        : ''
    } else {
      return t('common.onetime')
    }
  }

  if (elementVersion < 3) {
    return [
      {
        title: t('common.item'),
        key: 'item',
        width: 200,
        align: 'left',
        columnClass: 'px-0' + (elementVersion > 1 ? '' : ' pl-4'),
        renderCell(row: any) {
          return h(
            UITextSmMedium,
            {
              class: 'text-gray-900 text-left',
            },
            {
              default: () => row.name,
            }
          )
        },
      },
      {
        title: t('common.price'),
        key: 'priceText',
        width: 150,
        align: 'left',
        renderCell(row: any) {
          return h('div', {}, [
            h(UITextSmRegular, {
              class: 'text-left',
              innerHTML: row.priceText,
              align: 'left',
            }),
            h(UITextXsRegular, {
              class: 'text-left text-gray-400  mt-1',
              innerHTML: getPriceHelperText(row),
              align: 'left',
            }),
          ])
        },
      },
      {
        title: t('common.quantity'),
        key: 'qty',
        align: 'left',
        editable: true,
        columnClass: 'px-0',
        renderCell(row: any) {
          return h(ShowEditTableRow as unknown as DefineComponent, {
            isNumber: true,
            value: row.qty,
            min: row.minQty || 0,
            max: row.maxQty || Infinity,
            editable: row.qtyEditable,
            name: 'qty',
            onUpdateValue(value: any) {
              onUpdate('qty', value, row)
            },
          })
        },
      },
      {
        title: t('common.subtotal'),
        key: 'subtotal',
        align: 'right',
        renderCell(row: any) {
          const { formatCurrency } = getCurrencyUtil(
            currencyCode,
            CurrencyConfigs
          )
          return h(
            UITextSmMedium,
            {
              class: 'text-gray-900 text-right',
            },
            {
              default: () => formatCurrency(row.subtotal, currencySymbol),
            }
          )
        },
      },
    ]
  }
  return [
    {
      title: t('common.item'),
      key: 'item',
      width: 250,
      align: 'left',
      columnClass: 'px-0' + (elementVersion > 1 ? '' : ' pl-4'),
      renderCell(row: any) {
        return h('div', {}, [
          h(
            DynamicEditableField as DefineComponent<DynamicEditableFieldProps>,
            {
              value: row.name,
              name: 'name',
              editable: false,
              onUpdateValue() {
                return
              },
              id: row.key,
            }
          ),
          h(
            DynamicEditableField as DefineComponent<DynamicEditableFieldProps>,
            {
              id: row.key,
              richText: row.description,
              name: 'description',
              editable: false,
              isRichText: true,
              rowHovered: false,
              onUpdateValue() {
                return
              },
            },
            []
          ),
        ])
      },
    },
    {
      title: t('common.price'),
      key: 'priceText',
      width: 100,
      align: 'left',
      columnClass: 'pt-6',
      renderCell(row: any) {
        return h('div', {}, [
          h(UITextSmRegular, {
            class: 'text-left',
            innerHTML: row.priceText,
            align: 'left',
          }),
          h(UITextXsRegular, {
            class: 'text-left text-gray-400  mt-1',
            innerHTML: getPriceHelperText(row),
            align: 'left',
          }),
        ])
      },
    },
    {
      title: t('common.quantity'),
      key: 'qty',
      align: 'left',
      editable: true,
      columnClass: 'px-0',
      renderCell(row: any) {
        return h(
          DynamicEditableField as DefineComponent<DynamicEditableFieldProps>,
          {
            id: row.key,
            isNumber: true,
            value: row.qty,
            min: row.minQty || 0,
            max: row.maxQty || Infinity,
            editable: row.qtyEditable,
            name: 'qty',
            onUpdateValue(value: any) {
              onUpdate('qty', value, row)
            },
          }
        )
      },
    },
    {
      title: t('common.subtotal'),
      key: 'subtotal',
      align: 'right',
      columnClass: 'pt-6',
      renderCell(row: any) {
        const { formatCurrency } = getCurrencyUtil(
          currencyCode,
          CurrencyConfigs
        )
        return h(
          UITextSmMedium,
          {
            class: 'text-gray-900 text-right',
          },
          {
            default: () => formatCurrency(row.subtotal, currencySymbol),
          }
        )
      },
    },
  ]
}
