<script setup lang="ts">
import { UITextSmMedium, UITextMdNormal, UIButton } from '@gohighlevel/ghl-ui'
import {
  Flag05Icon,
  Edit04Icon,
  InfoOctagonIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import { useDocumentStore } from '../../store/document'
import { computed, inject } from 'vue'
import { useTypography } from '@gohighlevel/ghl-proposals-common'
import { useMobileView } from '@/composable'
import DocumentProgress from '@/components/common/DocumentProgress.vue'
import { formatDate } from '@/util'

const store = useDocumentStore()
const isPDFView = inject<boolean>('isPDFView')
const isPreview = inject<boolean>('isPreview')
const isCCrecipient = computed(() => store.isCcRecipient)
const expiresOn = computed(() =>
  formatDate(store.document?.expiresOn ?? '', 'D MMMM YYYY')
)
const showExpiryMessage = computed(() => {
  if (store.isAlreadyCompleted || store.isDraftDocument) {
    return false
  }
  return (
    store.documentExpiryEnabled &&
    store.isDocumentExpired &&
    !isPDFView &&
    isCCrecipient.value === false
  )
})

const isStartFillingOutDisabled = computed(
  () =>
    !!(
      isPreview ||
      isCCrecipient.value ||
      (store.documentExpiryEnabled && store.isDocumentExpired)
    )
)
const { toCapitalize } = useTypography()
const { isMobile } = useMobileView()

const requiredSigns = computed(
  () =>
    store.signatureElementCountForRecipient -
    store.signedSignatureElementCountForRecipient
)
const isEditable = computed(() => {
  return store.isEditable && !store.activeRecipient?.hasCompleted
})
const prevSigners = computed(() =>
  store.previousUnSignedRecipients
    .map(({ firstName, lastName }) => {
      let fullName = ''
      if (firstName) {
        fullName = firstName
      }
      if (lastName) {
        fullName = fullName + (fullName ? ' ' : '') + lastName
      }
      return toCapitalize(fullName)
    })
    .join(', ')
)
</script>

<template>
  <template v-if="isEditable && store.hasPrevRecipientsCompleted">
    <div
      :style="{
        width: '816px',
        zIndex: 1,
      }"
      class="mx-auto page:!w-[100%] sticky"
      :class="{
        'top-[6em]': !isMobile,
        '[@media(max-width:816px)]:fixed mobile-view--bottom-bar':
          isMobile && store.isEditing,
        '[@media(max-width:816px)]:top-[5em] [@media(max-width:816px)]:mb-[0em]':
          isMobile && !store.isEditing,
        'bottom-[2.5em]': isMobile && store.isEditing && isPreview,
        'bottom-[0em]': isMobile && store.isEditing && !isPreview,
      }"
    >
      <div
        class="p-2 border border-primary-300 rounded-lg bg-primary-25 shadow-md [@media(max-width:520px)]:rounded-none"
      >
        <div
          v-if="!store.isEditing"
          class="flex items-center justify-between page:mx-4 [@media(max-width:520px)]:flex-col [@media(max-width:520px)]:items-stretch [@media(max-width:520px)]:gap-3"
        >
          <div class="flex items-center">
            <div class="bg-primary-100 p-2 rounded-lg flex mr-4">
              <Flag05Icon class="h-4 w-4 text-primary-600" />
            </div>
            <UITextSmMedium class="text-primary-700">
              {{ $t('documentViewer.thisDocumentHas') }}
            </UITextSmMedium>
            <UITextMdNormal class="text-primary-600 ml-2">
              {{ requiredSigns }} {{ $t('documentViewer.requiredFields') }}
            </UITextMdNormal>
          </div>
          <div>
            <UIButton
              :disabled="isStartFillingOutDisabled"
              id="start-filling-doc-btn"
              type="primary"
              class="hover:bg-primary-400 shadow-lg"
              @click="() => store.setIsEditing(true)"
            >
              {{ $t('documentViewer.startFillingOut') }}
              <Edit04Icon class="text-white h-4 w-5" />
            </UIButton>
          </div>
        </div>
        <div
          v-else
          class="flex flex-1 items-center justify-between page:mx-4 [@media(max-width:520px)]:flex-col [@media(max-width:520px)]:items-stretch [@media(max-width:520px)]:gap-3"
        >
          <DocumentProgress />
        </div>
      </div>
    </div>
  </template>
  <template
    v-if="
      isEditable &&
      !store.hasPrevRecipientsCompleted &&
      !isPDFView &&
      isCCrecipient === false
    "
  >
    <div
      style="width: 816px"
      class="mt-4 mx-auto page:!w-[100%] sticky"
      :class="{
        'top-[6em]': !isMobile,
        '[@media(max-width:816px)]:sticky !mt-20': isMobile && store.isEditing,
        '[@media(max-width:816px)]:top-[6em] [@media(max-width:816px)]:mb-[0em]':
          isMobile && !store.isEditing,
      }"
    >
      <div
        class="p-2 border border-error-300 rounded-lg bg-error-25 shadow-md flex items-center justify-between page:mx-4 [@media(max-width:520px)]:flex-col [@media(max-width:520px)]:items-stretch [@media(max-width:520px)]:gap-3"
      >
        <div class="flex items-center">
          <div class="bg-error-100 p-2 rounded-lg flex mr-4">
            <InfoOctagonIcon class="h-4 w-4 text-error-600" />
          </div>
          <UITextSmMedium class="text-error-700">
            {{ $t('documentViewer.waitingFor') }}
            <strong>{{ prevSigners }}</strong>
            {{ $t('documentViewer.toCompleteTheirActions') }}
          </UITextSmMedium>
        </div>
      </div>
    </div>
  </template>
  <template v-if="showExpiryMessage">
    <div
      style="width: 816px"
      class="mt-4 mx-auto page:!w-[100%] sticky"
      :class="{
        'top-[6em]': !isMobile,
        '[@media(max-width:816px)]:sticky !mt-20': isMobile && store.isEditing,
        '[@media(max-width:816px)]:top-[6em] [@media(max-width:816px)]:mb-[0em]':
          isMobile && !store.isEditing,
      }"
    >
      <div
        class="p-2 border border-error-300 rounded-lg bg-error-25 shadow-md flex items-center justify-between page:mx-4 [@media(max-width:520px)]:flex-col [@media(max-width:520px)]:items-stretch [@media(max-width:520px)]:gap-3"
      >
        <div class="flex items-center">
          <div class="bg-error-100 p-2 rounded-lg flex mr-4">
            <InfoOctagonIcon class="h-4 w-4 text-error-600" />
          </div>
          <UITextSmMedium class="text-error-700">
            {{
              $t('documentViewer.expiredWarning', {
                date: expiresOn,
              })
            }}
          </UITextSmMedium>
        </div>
      </div>
    </div>
  </template>
</template>

<style lang="scss">
.mobile-view--bottom-bar {
  bottom: 0;
}
</style>
