<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'
import {
  IElement,
  PageElementOptions,
  DEFAULT_PAGE_DIMENSION,
  OLD_PAGE_HEIGHT,
  OLD_PAGE_WIDTH,
} from '@gohighlevel/ghl-proposals-common'
import BlocksRenderer from '../common/BlocksRenderer.vue'
import FloatingElementRenderer from '../common/FloatingElementRenderer.vue'

const props = defineProps<{
  page: IElement<PageElementOptions>
  allPagesVersionGreaterThanOne: boolean
}>()
const isPDFView = inject<boolean>('isPDFView')
const pageRef = ref<HTMLElement>()
const pageParentRef = ref<HTMLElement>()
const pageViewHeight = ref()

const pageHeight = computed(() => {
  if (props.allPagesVersionGreaterThanOne) {
    const { dimensions } =
      props.page.component.options.pageDimensions || DEFAULT_PAGE_DIMENSION
    return dimensions.height
  }
  return OLD_PAGE_HEIGHT
})

const calculatePageHeight = (currentHeight: number) => {
  if (currentHeight > pageHeight.value) {
    const multiple = Math.ceil(currentHeight / pageHeight.value)
    const h = multiple * (pageHeight.value - 96) + multiple * 2 * 48
    pageViewHeight.value = h
  } else {
    pageViewHeight.value = currentHeight
  }
}

watch(pageRef, newValue => {
  if (newValue) {
    setTimeout(() => {
      calculatePageHeight(pageRef.value?.offsetHeight as number)
    }, 200)
  }
})

const backgroundSize = computed(() => {
  if (isPDFPage(props.page)) {
    if (props.allPagesVersionGreaterThanOne) {
      const { dimensions } =
        props.page.component.options.pageDimensions || DEFAULT_PAGE_DIMENSION
      return `${dimensions.width}px ${dimensions.height}px`
    } else {
      return `${OLD_PAGE_WIDTH}px ${OLD_PAGE_HEIGHT}px`
    }
  }
  return props.page?.responsiveStyles?.large?.backgroundSize
})

const isPDFPage = (page: any) => page?.component.options.fromPDF

const pageStyles = computed(() => {
  const backgroundSizeStyle = backgroundSize.value
  return {
    backgroundColor:
      props.page?.responsiveStyles?.large?.backgroundColor || null,
    backgroundImage: `url('${props.page?.component?.options?.src}')`,
    backgroundPosition: props.page?.responsiveStyles?.large?.backgroundPosition,
    backgroundRepeat: props.page?.responsiveStyles?.large?.backgroundRepeat,
    backgroundSize: backgroundSizeStyle,
    opacity: props.page?.responsiveStyles?.large?.opacity / 100 || 1,
  }
})

const getDraggableElement = (children: IElement[]) => {
  if (!children) return []
  return children.filter(element => element && element?.component?.isDraggable)
}

watch(
  pageParentRef,
  updatedPageRef => {
    pageParentRef.value = updatedPageRef
  },
  { immediate: true }
)

const pageContentHeight = computed(() => {
  if (props.allPagesVersionGreaterThanOne) {
    const { dimensions, margins } =
      props.page?.component.options.pageDimensions || DEFAULT_PAGE_DIMENSION
    return `${dimensions.height - margins.top - margins.bottom}px`
  }
  return DEFAULT_PAGE_DIMENSION.dimensions.height + 'px'
})
</script>

<template>
  <div class="page-container" style="display: block" ref="pageRef">
    <div
      ref="pageParentRef"
      class="page"
      :style="{ minHeight: `${pageViewHeight}px` }"
    >
      <table class="border-collapse page-layout-table">
        <thead class="page-layout-table-head">
          <tr>
            <td class="page-layout-table-cell p-0">
              <!--place holder for the fixed-position header-->
              <div class="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody class="page-layout-table-body">
          <tr>
            <td class="page-layout-table-cell p-0">
              <div :id="props.page.id" class="page-view">
                <div class="page-header header-footer" v-if="!isPDFView">
                  <div class="page-header-content"></div>
                </div>
                <div class="page-content parent">
                  <div
                    class="document-block"
                    style="
                      flex: 1 1 0%;
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                    "
                  >
                    <FloatingElementRenderer
                      :elements="getDraggableElement(page.children)"
                      :pageId="page.id"
                      :page="props.page"
                      :pageRef="pageParentRef"
                      :parentRef="pageParentRef"
                    />
                    <template
                      v-for="element in page.children"
                      v-bind:key="'NestedElementRenderer-' + element.id"
                    >
                      <BlocksRenderer
                        :page="page"
                        :pageRef="pageParentRef"
                        :parentRef="pageParentRef"
                        :element="(element as IElement<any>)"
                        :pageId="(page.id as string)"
                        v-if="!element.component.isDraggable"
                      />
                    </template>
                  </div>
                </div>
                <div class="page-footer header-footer" v-if="!isPDFView">
                  <div class="page-footer-content"></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot class="page-layout-table-foot">
          <tr>
            <td class="page-layout-table-cell p-0">
              <!--place holder for the fixed-position footer-->
              <div class="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page {
  position: relative;
  &::before {
    position: fixed;
    content: '';
    z-index: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
    transform: translateZ(0px);
    top: 0;
    background-color: v-bind('pageStyles.backgroundColor');
    background-image: v-bind('pageStyles.backgroundImage');
    background-position: v-bind('pageStyles.backgroundPosition');
    background-repeat: v-bind('pageStyles.backgroundRepeat');
    background-size: v-bind('pageStyles.backgroundSize');
    opacity: v-bind('pageStyles.opacity');
  }
}
.page-view {
  min-height: v-bind('pageContentHeight');
  width: 816px;
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
@media print {
  .page-layout-table {
    border-collapse: collapse;
    .page-layout-table-head {
      display: table-header-group;
    }
    .page-layout-table-body {
      display: table-cell;
    }
    .page-layout-table-foot {
      display: table-footer-group;
    }
    .page-layout-table-cell {
      padding: 0px;
      display: block;
    }
  }

  body {
    margin: 0;
  }
}
</style>
