<script setup lang="ts">
import { UIButton } from '@gohighlevel/ghl-ui'
import { FileDownload02Icon } from '@gohighlevel/ghl-icons/24/outline'
import { useDocument } from '@/composable'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { loading, downloadProposalPdf } = useDocument()

const onSelect = async () => {
  await downloadProposalPdf()
}
</script>

<template>
  <UIButton
    class="hover:text-primary-600 text-white"
    :loading="loading"
    id="document-state-actions-btn"
    :circle="true"
    :quaternary="true"
    @click="onSelect"
    :title="t('documentViewer.downloadPdf')"
  >
    <FileDownload02Icon class="w-5 h-5 text-white" />
  </UIButton>
</template>
