<script setup lang="ts">
import {
  CheckBoxElementOptions,
  IElement,
  IRecipient,
  Tooltip,
  getSignatureElementColorStyles,
} from '@gohighlevel/ghl-proposals-common'
import { UITextSmRegular, UICheckbox } from '@gohighlevel/ghl-ui'
import { PropType, computed, inject, onMounted, ref, toRef } from 'vue'
import { useDocumentStore } from '@/store/document'
import { useFillableFields, useGroups, useTypography } from '@/composable'

const isPDFView = inject<boolean>('isPDFView')
const isPreview = inject<boolean>('isPreview')
const store = useDocumentStore()
const { toCapitalize } = useTypography()
const { setActiveField } = useFillableFields()
const { isElementPartOfGroup, activeGroup } = useGroups()
const isCCrecipient = computed(() => store.isCcRecipient)
const isChecked = ref()

const props = defineProps({
  element: Object as PropType<IElement<CheckBoxElementOptions>>,
  pageId: {
    type: String,
    required: true,
  },
  tabIndex: {
    type: Number,
    required: true,
  },
})

const isInGroup = computed(() => {
  if (props.element) {
    const group = isElementPartOfGroup(props.element.id)
    if (group) {
      return true
    }
  }
  return false
})

const activeRecipient = computed(() => store.activeRecipient)
const styles = computed(() => getSignatureElementColorStyles(recipient.value))
const isEditing = computed(() => store.isEditing)
const isRecipientHasCompleted = computed(() => {
  return (
    activeRecipient.value &&
    activeRecipient.value?.hasCompleted &&
    isActiveTextFieldElemForRecipient.value
  )
})

const isActiveTextFieldElemForRecipient = computed(
  () =>
    activeRecipient.value?.id === props.element?.component.options.recipient &&
    activeRecipient.value?.entityName ===
      props.element?.component.options.entityName
)

const recipient = computed(() => {
  const recipients = store.document?.recipients || []
  return (
    recipients.find(
      ({ id, entityName }) =>
        id === props.element?.component.options.recipient &&
        entityName === props.element?.component.options.entityName
    ) || ({} as IRecipient)
  )
})
const date = toRef(recipient.value.signedDate)

const recipientName = computed(() => {
  const { firstName, lastName } = recipient.value
  return toCapitalize(firstName ? `${firstName} ${lastName || ''}` : '')
})

const documentExpiryEnabled = computed(() => {
  if (store.documentExpiryEnabled) return true

  return false
})
const isDocumentExpired = computed(() => {
  if (store.isDocumentExpired) return true

  return false
})

const disabled = computed(() => {
  return (
    !store.hasPrevRecipientsCompleted ||
    (!isPreview &&
      !isActiveTextFieldElemForRecipient.value &&
      !isRecipientHasCompleted.value) ||
    isCCrecipient.value ||
    (documentExpiryEnabled.value && isDocumentExpired.value)
  )
})

const onClick = () => {
  if (
    !isEditing.value &&
    isActiveTextFieldElemForRecipient.value &&
    !activeRecipient.value?.hasCompleted &&
    isCCrecipient.value === false
  ) {
    store.setIsEditing(true)
  }
  if (!isPDFView && !isPreview) {
    const group = isElementPartOfGroup(props.element?.id)
    if (group) {
      setActiveField(group?.id as string)
    } else {
      setActiveField(props.element?.id as string)
    }
  }
}

const ghostStyles = computed(() => {
  if (props.element?.component.options?.isGhost) {
    const styleMap = props.element?.responsiveStyles.large as any
    const position = styleMap.position
    const scale = styleMap.scale
    const dimensions = styleMap.dimensions

    const stylesArray = []
    if (position) {
      let styleStr = `top: ${position.top}px; z-index: 100;`
      styleStr += `left: ${position.left}px;`

      stylesArray.push(styleStr)
    }
    if (scale) {
      stylesArray.push(
        `transform: scale(${scale.scaleX}, ${scale.scaleY}) translate3d(0,0,0);`
      )
    }
    if (dimensions) {
      stylesArray.push(
        `height: ${dimensions.height}px; width: ${dimensions.width}px;`
      )
    }
    stylesArray.push('position: absolute;  display: flex;')

    return stylesArray.join(' ').trim()
  }
  return ''
})

const dimensions = computed(() => {
  const dimensionsObj = props.element?.responsiveStyles.large.dimensions
  if (dimensionsObj?.height && dimensionsObj?.width) {
    return {
      height: dimensionsObj.height + 'px',
      width: dimensionsObj.width + 'px',
    }
  } else {
    return { height: 'auto', width: 'auto' }
  }
})

const onUpdateCheckBoxField = (value: boolean) => {
  if ((!isPDFView && !isRecipientHasCompleted.value) || isPreview) {
    const group = isElementPartOfGroup(props.element.id)
    if (!group) {
      if (props.element?.component?.options?.preChecked && !value) {
        store.removeVisitedOptionalElement(props.element.id)
      }
    }
    const timestamp = new Date()
    date.value = timestamp.toISOString()
    store.updateFillableFieldForRecipient(
      recipient.value.id,
      value.toString(),
      props.element?.id as string,
      date.value
    )
    store.updateFillableElement(
      props.element?.id,
      props.pageId,
      value.toString(),
      true
    )
  }
  setIsChecked()
}

const setIsChecked = () => {
  const options = props.element?.component?.options
  if (options?.text) {
    const value = options?.text === 'true' ? true : false
    isChecked.value = value
  } else {
    const preChecked = props.element?.component.options.preChecked
    isChecked.value = preChecked
  }
}

onMounted(() => {
  setIsChecked()
})

const onMouseEnter = () => {
  if (props.element) {
    const group = isElementPartOfGroup(props.element.id)
    if (group) {
      activeGroup.value = group
    } else {
      activeGroup.value = null
    }
  }
}

const onMouseLeave = () => {
  activeGroup.value = null
}
</script>

<template>
  <div
    @mouseover="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click="onMouseEnter"
    class="checkbox-field-el"
    :data-element-id="props.element?.id"
    :style="ghostStyles"
    :class="{
      filled: props.element?.component.options.text,
      groupField: isInGroup,
      'checkbox-disabled-pdf': isPDFView,
    }"
    :id="props.element?.id"
  >
    <Tooltip direction="bottom" :enable="true">
      <template #trigger>
        <div
          class="print:break-inside-avoid !break-inside-avoid box-border text-field-el-wrapper-viewer"
          @click="onClick"
        >
          <span
            v-if="
              element?.component.options.required &&
              !disabled &&
              !isPDFView &&
              !isRecipientHasCompleted &&
              !isInGroup
            "
            class="option--required"
            >*</span
          >
          <UICheckbox
            :style="styles"
            :tabindex="tabIndex"
            :key="props.element?.id"
            :id="`floating_checkbox_field-${props.element?.id}`"
            :type="'default'"
            :disabled="disabled"
            :checked="isChecked"
            @update:checked="(val: boolean) => {
              onUpdateCheckBoxField(val)
            }"
          />
        </div>
      </template>

      <template #content>
        <UITextSmRegular
          v-if="!isInGroup"
          class="capitalize font-semibold px-2 py-1 text-xs"
          :style="{ color: styles.placeholderColor }"
          >{{ recipientName }}</UITextSmRegular
        >
      </template>
    </Tooltip>
  </div>
</template>

<style lang="scss">
.checkbox-field-el {
  width: v-bind('dimensions.width') !important;
  height: v-bind('dimensions.height') !important;

  .n-checkbox {
    --n-border: 1px solid v-bind('styles.borderColor') !important;
    padding: 0 !important;
    width: v-bind('dimensions.width') !important;
    height: v-bind('dimensions.height') !important;
    --n-size: v-bind('dimensions.width') !important;
  }
}

.checkbox-disabled-pdf {
  .n-checkbox {
    --n-border: 1px solid v-bind('styles.borderColor') !important;
    --n-bezier: cubic-bezier(0.4, 0, 0.2, 1);
    --n-border-disabled-checked: 1px solid #155eef !important;
    --n-check-mark-color: #155eef !important;
    --n-color-checked: #d1e0ff !important;
    --n-check-mark-color-disabled: #155eef;
    --n-check-mark-color-disabled-checked: #155eef !important;
    --n-color-disabled-checked: #d1e0ff;
  }
}
</style>
