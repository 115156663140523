import { onMounted, onUnmounted, ref } from 'vue'

const isMobile = ref(false)
const scaleFactor = ref(1)
let resizeObserver: ResizeObserver | null = null

export const useMobileViewV2 = () => {
  const updateView = () => {
    const windowWidth = window.innerWidth
    const divWidth = 816

    if (windowWidth <= divWidth) {
      isMobile.value = true
      // More stable scaling calculation
      const scale = windowWidth / divWidth
      // Limit scale between 0.5 and 0.95 for better stability
      scaleFactor.value = Math.max(0.5, Math.min(0.95, scale))
    } else {
      isMobile.value = false
      scaleFactor.value = 1
    }
  }

  onMounted(() => {
    // Initial update
    updateView()

    // Use ResizeObserver instead of resize event for better performance
    resizeObserver = new ResizeObserver(entries => {
      if (!entries.length) return

      // Use requestAnimationFrame to avoid layout thrashing
      requestAnimationFrame(() => {
        updateView()
      })
    })

    resizeObserver.observe(document.documentElement)
  })

  onUnmounted(() => {
    if (resizeObserver) {
      resizeObserver.disconnect()
      resizeObserver = null
    }
  })

  return {
    isMobile,
    scaleFactor,
  }
}
